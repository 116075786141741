import styled from "styled-components";

export const StyledWrapper = styled.header`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  padding: 3rem 2rem 3rem 2rem;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 4rem 2rem 0 2rem;
    margin-bottom: 0;
  }
`;
export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 0 2rem 0;
  }
`;
export const StyledTitle = styled.h1`
  line-height: 4rem;
  font-size: 3.375rem;
  font-weight: 700;
  margin: 0 0 30px 0;
  span {
    color: var(--text-color);
  }
  @media screen and (max-width: 768px) {
    line-height: 4.2rem;
    font-size: 3rem;
  }
`;
export const StyledBody = styled.div`
  margin-bottom: 48px;
  color: var(--text-color);
  font-size: 1.2rem;
  line-height: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 16px;
    line-height: 1.7rem;
  }
`;
export const StyledHalf = styled.div`
  padding: 4rem 4rem 4rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 50%;
  min-width: 50%;
  position: relative;
  @media screen and (max-width: 992px) {
    flex: ${(props) => props.tabletFlex || "1"};
  }
  @media screen and (max-width: 768px) {
    padding: 2rem 0 2rem 0;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
`;
export const StyledHalfImage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  position: relative;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;
export const StyledSvgWrapper = styled.div`
  height: ${(props) => props["data-height"] || "500px"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 260px;
    width: 100%;
  }
`;
