import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const animationRedact = keyframes`
  0% {
    transform: scaleX(0%) rotate(1deg) skewx(-16deg);
  }
  40% {
    transform: scaleX(0%) rotate(1deg) skewx(-16deg);
  }
  75% {
    transform: scaleX(100%) rotate(.5deg) skewx(-8deg);
  }
  100% {
    transform: scaleX(100%) rotate(.5deg) skewx(-8deg);
  }
`;
const animationFade = keyframes`
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledObject = styled.div`
  animation-fill-mode: both;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  animation-name: ${animationFade};
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-fill-mode: both;
  overflow: visible;
  > div {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left;
    content: "";
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    height: 100%;
    width: 100%;
    background-color: #000;
    animation-name: ${animationRedact};
    animation-duration: 1.8s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
  }
  background: linear-gradient(270deg, #7551cb 3.49%, #5172c8 92.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const redactables = [
  "messages",
  "Reddit posts",
  "Discord messages",
  "tweets",
  "Facebook posts",
  "retweets",
  "Slack messages",
  "Reddit posts",
  "Instagram comments",
  "digital footprint",
  "Steam comments",
];

const HeroTitle = ({left}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev >= redactables.length - 1 ? 0 : prev + 1));
    }, 1800);
    return () => clearInterval(interval);
  }, []);
  return (
    <h1 className="hero__title" style={{ textAlign: left ? "left" : "center" }}>
      Mass delete your <br />
      <StyledObject key={redactables[currentIndex]}>
        {redactables[currentIndex]}
        <div />
      </StyledObject>
    </h1>
  );
};

export default HeroTitle;
